import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Layout, Menu } from 'antd'
import { useContext, useState } from 'react'
import { Session } from '../state/SessionContainer'
import Character from './Character'
import SsoLogin from './SsoLogin'
import { DateTime } from 'luxon'

export const TobBar = ({ activeKey, setActiveKey }) => {
    const { Header } = Layout
    const session = useContext(Session)
    const [menuOpen, setMenuOpen] = useState(false)

    const menuDesktop = (
        <Menu
            className="desktop-menu"
            theme="dark"
            mode="horizontal"
            selectedKeys={[activeKey]}
            style={{ overflow: 'hidden' }}>
            <Menu.Item onClick={() => setActiveKey('0')} key="0">
                <a>Home</a>
            </Menu.Item>
            <Menu.Item onClick={() => setActiveKey('1')} key="1">
                Map
            </Menu.Item>
            {session?.character?.level >= 1 && (
                <Menu.Item
                    onClick={() => {
                        setActiveKey('2')
                    }}
                    key="2">
                    Connections
                </Menu.Item>
            )}
            {session?.character?.level >= 2 && (
                <Menu.Item onClick={() => setActiveKey('3')} key="3">
                    Route planner
                </Menu.Item>
            )}

            <Menu.Item onClick={() => setActiveKey('4')} key="4">
                Scan Stats
            </Menu.Item>
            {session?.character?.admin && (
                <Menu.Item onClick={() => setActiveKey('5')} key="5">
                    Admin
                </Menu.Item>
            )}
            <Menu.Item style={{ display: 'flex', padding: '0', marginLeft: '1rem' }}>
                {session?.character ? <Character setActiveKey={setActiveKey} /> : <SsoLogin />}
                {session?.character?.subUntill
                    ? `Sub untill: ${DateTime.fromISO(session?.character?.subUntill).toISODate()} `
                    : ''}
            </Menu.Item>
        </Menu>
    )

    const menuMobile = (
        <Menu
            theme="dark"
            mode="vertical"
            selectedKeys={[activeKey]}
            style={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
            <Menu.Item
                onClick={() => {
                    setActiveKey('0')
                    setMenuOpen(false)
                }}
                key="0">
                <a>Home</a>
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    setActiveKey('1')
                    setMenuOpen(false)
                }}
                key="1">
                Map
            </Menu.Item>
            {session?.character?.level >= 1 && (
                <Menu.Item
                    onClick={() => {
                        setActiveKey('2')
                        setMenuOpen(false)
                    }}
                    key="2">
                    Connections
                </Menu.Item>
            )}
            {session?.character?.level >= 2 && (
                <Menu.Item
                    onClick={() => {
                        setActiveKey('3')
                        setMenuOpen(false)
                    }}
                    key="3">
                    Route planner
                </Menu.Item>
            )}

            <Menu.Item
                onClick={() => {
                    setActiveKey('4')
                    setMenuOpen(false)
                }}
                key="4">
                Scan stats
            </Menu.Item>
            {session?.character?.admin && (
                <Menu.Item
                    onClick={() => {
                        setActiveKey('5')
                        setMenuOpen(false)
                    }}
                    key="5">
                    Admin
                </Menu.Item>
            )}
            <Menu.Item style={{ alignSelf: 'flex-end' }}>
                {session?.character ? <Character setActiveKey={setActiveKey} /> : <SsoLogin />}
                {session?.character?.subUntill
                    ? `Sub untill: ${DateTime.fromISO(session?.character?.subUntill).toISODate()} `
                    : ''}
            </Menu.Item>
        </Menu>
    )

    return (
        <Header className="header" style={{ display: 'flex', padding: '0' }}>
            {menuDesktop}
            <div className="mobile-menu">
                <Dropdown overlay={menuMobile} onVisibleChange={setMenuOpen} visible={menuOpen}>
                    <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                        Menu <DownOutlined />
                    </a>
                </Dropdown>
            </div>
        </Header>
    )
}
